import { APP_VERSION } from '@aa/angular/core';
import { CustomerAppLayoutComponent } from '@aa/angular/customer';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterModule, CustomerAppLayoutComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(private matIconReg: MatIconRegistry) {}

  ngOnInit(): void {
    console.log('APP VERSION', APP_VERSION);
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }
}
